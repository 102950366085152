.ReviewToolbar {
    align-items: center;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    padding: 0.5em 0.5em 0;
    position: relative;

    &__Title {
        color: #016848;
        font-weight: bold !important;
    }

    &__Button {
        color: #016848 !important;
        height: 45px;
        justify-self: flex-start;
        padding: 0;

        .MuiButton-label {
            padding: 2px 8px;
        }

        input {
            display: none;
        }
    }

    &__actions {
        justify-self: flex-end;

        &-filterbBox {
            background: #fff;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);
            position: absolute;
            right: 2em;
            top: 4em;
            z-index: 1000;
        }
    }
}

.ReviewListTable {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: auto;
    padding: 0 0.5em;

    .MuiPaper-root {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    .MuiTableCell-head {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.75rem;
        font-weight: 500;
    }

    .MuiTableCell-paddingCheckbox {
        padding: 0;
        width: 48px;
    }

    .MuiTablePagination-root {
        align-items: center;
        color: #707070;
        display: flex;
        flex-direction: column;
        width: 100%;

        .MuiTypography-body2 {
            font-size: 14px;
        }
    }

    .MuiTableCell-root {
        padding: 0;

        .MuiButtonBase-root {
            color: #4a4a4a;
            font-size: small;
            font-weight: bold;
        }

        .MuiIconButton-label {
            svg {
                color: #016848;
            }
        }

        // centering the Related Models data to match mockups
        &.ReviewListTable__match {
            padding-right: 18px;
            text-align: center;
        }
    }

    .MuiTableRow-root {
        &.Mui-selected {
            background-color: transparent;
        }
    }
}
