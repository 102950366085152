.MeanTimeViewer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
        margin: 0;
    }

    &__mtformControl {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .MuiInputBase-root {
            min-width: 146px !important;
        }

        &-input {
            align-items: center;
            align-self: center;
            background-color: #c5e8f34d;
            display: flex;
            flex-direction: column;
            height: 95px;
            width: 100%;

            input {
                color: #1a3a44;
                font-weight: bold;
                padding: 1.5em;
                text-align: center;
            }

            .MuiTypography-colorTextSecondary {
                color: #016848;
                font-weight: bold;
            }
        }

        .MuiFormLabel-root.Mui-disabled {
            color: rgba(0, 0, 0, 0.54);
        }
        &-secondaryMenuItem {
            font-size: 0.6em;
            margin-left: 0.2em;
        }

        &-select {
            margin: 1em !important;
            min-width: 146px !important;
            width: 100%;
        }
    }
    .MuiInput-underline.Mui-disabled::before {
        border-bottom-style: 0;
    }
}
