.ControlPanel {
    padding: 0.5em;
    width: 100%;

    &__axis {
        display: flex;
        flex-direction: row;
    }

    &__plotCPformControl {
        margin: 1em !important;
        min-width: 146px !important;
        width: 100%;

        &-top {
            padding: 0 1rem !important;
            width: 100%;

            label {
                padding: 0 1rem !important;
            }
        }
    }
}

.ControlsArea {
    &__tabs {
        &-timePickerContainer {
            border-bottom: 0;
            display: flex;
            flex-direction: row;
            gap: 0.5em;
            justify-content: space-between;
            margin-bottom: 0.5em;
            width: 100%;

            &-timePicker {
                width: 50% !important;

                input[name='endFilter'] {
                    border: 1px solid #d6d6d6;
                    border-radius: 4px;
                    box-shadow: 0 3px 4px #00000024;
                    font-size: 0.85em !important;
                    padding-left: 0.5em;
                }

                input[name='startFilter'] {
                    border: 1px solid #d6d6d6;
                    border-radius: 4px;
                    box-shadow: 0 3px 4px #00000024;
                    font-size: 0.85em !important;
                    padding-left: 0.5em;
                }
            }
        }

        &-filterFormControl {
            width: 100%;

            &-filterSelects {
                margin-bottom: 1em;
                white-space: normal !important;
                width: 100% !important;
            }
        }

        &-filterSelectsContainer {
            width: 100%;
        }
    }

    &__tabContainer {
        background-color: #d0df63;

        .MuiTab-textColorPrimary {
            color: #016848;
        }
    }
}
