.DataOrgWorkspace {
    padding: 1em;

    .MuiGrid-container {
        flex-basis: 100%;
        flex-grow: 0;
        width: calc(100% + 24px);
    }

    &__spinner {
        left: 50%;
        margin: -20px 0 0 -20px;
        position: absolute;
        top: 50%;
    }

    &__review-list {
        height: calc(((100vh - 85.5px - 6em) / 2) + 30px);
        overflow-y: auto;
        position: relative;
    }

    &__models-list {
        height: calc(((100vh - 85.5px - 6em) / 2) - 30px);
        overflow-y: auto;
        position: relative;
    }

    &__models-expanded {
        height: calc(100vh - 89.5px - 4em);
        overflow-y: auto;

        .Badge {
            min-width: 1.5em;
        }

        .Indicator {
            margin: 0 -2em 0 0;
        }

        &-placeholder {
            height: 100%;
            padding: 1em;
            position: relative;
            text-align: center;

            h2 {
                color: #777;
                font-size: 20px;
                font-weight: bold;
                left: 0;
                margin-top: -1em;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }
    }
}
