.App {
    width: 100%;

    .MuiInput-underline::before {
        border-bottom: 0;
    }

    &__helpOpen {
        transition: width 0.2s;
        width: calc(100% - 350px);
    }

    &__help {
        .MuiDrawer-paperAnchorRight {
            transition: width 1s;
            width: 350px;
        }

        &-expanded {
            .MuiDrawer-paperAnchorRight {
                transition: width 1s;
                width: 950px;
            }
        }
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    align-items: center;
    background-color: #282c34;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    min-height: 100vh;
}

.App-link {
    color: #61dafb;
}

.Flex-layout {
    align-items: center;
    display: flex;
    gap: 0.5em;
    justify-content: flex-start;
}

.Tooltip-title {
    font-weight: 400;
    margin: 0;
    padding: 0.25em;
}
