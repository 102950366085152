.NavBar {
    display: block;

    &__logo {
        margin: 10px;
    }

    .Spotlight {
        &__text {
            left: 110%;
            position: absolute;
            top: 55%;

            &-title {
                border-bottom: #016848 1px solid;
                font-size: 20px;
                font-weight: 800;
                white-space: nowrap;
            }
            &-message {
                font-size: 16px;
                font-weight: 600;
                width: 300px;
            }

            &-continue {
                font-size: 14px;
                font-style: italic;
            }
        }
        &__help {
            position: absolute;
            right: 40%;
            top: 100%;

            &-title {
                border-bottom: #016848 1px solid;
                font-size: 20px;
                font-weight: 800;
                white-space: nowrap;
            }
            &-message {
                font-size: 16px;
                font-weight: 600;
                width: 300px;
            }

            &-continue {
                font-size: 14px;
                font-style: italic;
            }
        }
    }

    .MuiToolbar-root {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .NavBar__controls {
            align-items: center;
            display: flex;

            .NavBar__title {
                font-size: 21px;
                font-weight: bold;
                padding-right: 50px;
            }

            .NavBar__menuButton {
                margin-left: -12;
                margin-right: 20;
            }

            .NavBar__pages {
                display: flex;
                flex-direction: row;

                &-DataAnalysis {
                    div span {
                        font-weight: bold;
                        width: 110px;
                    }
                    &-selected {
                        div span {
                            border-bottom: 1px solid #d0df63;
                            color: #d0df63;
                            font-weight: bold;
                            min-width: 110px;
                        }
                    }
                }
                &-DataOrgWork {
                    div span {
                        font-weight: bold;
                        width: 165px;
                    }
                    &-selected {
                        min-width: 197px;
                        div span {
                            border-bottom: 1px solid #d0df63;
                            color: #d0df63;
                            font-weight: bold;
                            min-width: 160px;
                        }
                    }
                }
            }
        }
        .NavBar__help {
            align-items: center;
            color: #d0df63;
            display: flex;
            gap: 2em;

            .MuiInput-root {
                svg {
                    fill: #d0df63;
                }

                .MuiSelect-select {
                    color: #d0df63;
                    font-weight: bold;
                }
            }

            .MuiInput-underline::before {
                transition: none;
            }

            .MuiInput-underline:hover:not(.Mui-disabled)::before {
                border-color: #d0df63;
            }

            &-helpButton {
                margin-right: 10px;
            }
            .MuiIconButton-label {
                text {
                    font-size: small;
                }
            }
        }
    }
    .MuiTypography-colorInherit {
        font-size: 1.55em;
        font-weight: 540;
    }
}
