.Help {
    color: #1a3a44;
    display: grid;
    grid-template-areas: 'header' 'body';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;

    h1 {
        font-size: 1.1rem;
    }

    h2 {
        font-size: 0.9rem;
    }

    h3 {
        font-size: 0.7rem;
    }

    .Search {
        width: 100%;
    }

    &__header {
        align-items: center;
        border-bottom: 1px solid #dbe9e3;
        display: flex;
        grid-area: header;
        justify-content: space-between;
        padding: 1em 0.5em;

        h2 {
            font-size: 1.3rem;
            margin: 0;
            padding: 10px;
        }

        &-text {
            color: #1a3a44;
            display: flex;
        }
    }

    &__body {
        color: #1a3a44;
        grid-area: body;
        overflow-y: auto;
        padding: 0 20px;

        h4 {
            margin: 0;
            padding: 0;
        }

        img {
            max-width: 900px;
        }

        .MuiButtonBase-root {
            padding: 0;

            &:hover {
                background-color: #d0df634d;
                color: #016848;
            }

            .MuiListItemText-root {
                margin: 0;

                span {
                    font-size: small;
                    font-weight: bold;
                    padding: 10px 0 0 20px;
                }
            }

            .MuiListItemText-secondary {
                font-size: small;
                margin: 0;
                padding: 0 0 10px 20px;
            }
        }

        .MuiButton-colorInherit {
            background-color: #d0df63;
            box-shadow: none;
            color: #016848;
            font-weight: 800;
            height: 50px;
        }
    }

    &__footer {
        border-top: 1px solid #dbe9e3;
        margin-top: 5px;
        padding-bottom: 5px;
    }

    code {
        overflow-wrap: break-word;
        white-space: pre-wrap;
    }

    .MuiOutlinedInput-root {
        background: #fff;
    }
}
