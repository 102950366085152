.Plotting {
    padding: 0.5em;
    width: 100%;
    &__axis {
        display: flex;
        flex-direction: row;
    }

    &__meanTimeArea,
    &__controlsArea,
    &__plottingArea {
        border-top: 1px solid #ddd;
        margin: 1em 0 0 1.5em;

        h2 {
            color: #016848;
            font-size: large;
            font-weight: bold;
            padding-left: 1em;
            text-align: left;
        }

        .MuiSelect-select.MuiSelect-select {
            border: 1px solid #d6d6d6;
            border-radius: 4px;
            box-shadow: 0 3px 4px #00000024;
            padding-left: 8px;
            text-align: left;
        }
    }

    &__plottingArea {
        height: calc(100% - 1em);
        margin-left: 0;

        &-placeholder {
            height: 100%;
            padding: 1em;
            position: relative;
            text-align: center;

            h3 {
                color: #777;
                font-size: 20px;
                font-weight: bold;
                left: 0;
                margin-top: -1em;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }
    }

    &__controlsArea {
        height: calc(100% - 2.5em - 228px);
        margin-top: 0;

        &__tabs {
            header {
                width: 100%;
            }
        }

        &__tabContainer {
            div div {
                display: flex;
                button {
                    flex: 1;
                }
            }
        }
    }

    &__meanTimeArea {
        margin-top: 1em;
    }
}
