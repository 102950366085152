/* stylelint-disable max-nesting-depth */
.ModelsExpanded {
    padding: 1em;

    &__title {
        align-items: center;
        display: flex;
        gap: 0.5em;

        .MuiTypography-subtitle1 {
            color: #016848;
            font-weight: bold !important;
            margin-left: 0.5em !important;
        }
    }

    &__popOver {
        min-height: 15em;
        min-width: 20em;
        padding: 15px;

        button {
            margin-right: 12px;
        }
        &-popOverBtns {
            float: right;
            svg {
                cursor: pointer;
                margin: 0 0.2em 0.3em;
            }
        }
    }

    &__instancePopover {
        min-width: 20em;
        padding: 15px;
        text-align: center;

        div {
            font-weight: bold;
            margin-bottom: 1em;
        }

        .MuiButton-label {
            color: #c90000;
        }
    }

    &__models {
        display: flex;
        // overflow-x: auto;
        // overflow-y: hidden;
        // width: 100%;

        &-hide {
            display: none;
        }

        .MuiList-padding {
            padding-top: 0 !important;
        }

        &-modelPres {
            border-top: 1px solid #ddd;
            margin: 4.2em 0 0 1em !important;
            // align-items: baseline;
            // margin: 3em 1em !important;
            // margin-right: 0 !important;
            // min-width: 16em !important;

            .MuiListItem-root {
                padding: 0;
            }

            .Model__activity {
                .MuiTypography-body1 {
                    font-size: 0.75rem;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 150px;
                }

                .MuiTypography-body2 {
                    font-size: 0.75rem;
                }
            }

            &-mlistSubheader {
                color: #000 !important;
                display: flex;
                flex-wrap: nowrap;
                font-size: 10pt !important;
                font-weight: normal !important;
                padding: 11px 0 0 !important;
                position: relative !important;
            }

            &-activityList {
                border-bottom: 1px solid #ddd;
                height: 3.5em;
                padding: 0.2em !important;

                svg {
                    display: none;
                    margin: 0 0.5rem 0 0.1rem;
                }

                &-matched {
                    background-color: #edf8fb;
                    height: 3.5em;

                    &.MuiListItem-gutters {
                        padding-left: 0.2em;
                    }
                }

                .ToggleDefault {
                    display: none;
                }

                .ToggleBlock {
                    display: block;
                }
            }
        }
    }

    &__instances {
        display: flex;
        overflow-x: auto;

        &-instPres {
            display: block;
            min-width: 135px;

            .MuiListItem-root {
                justify-content: center;
                padding: 0;
            }

            &-mlistSubheader {
                align-items: center;
                border-bottom: 1px solid #ddd;
                display: flex;
                font-size: 10pt !important;
                font-weight: bold !important;
                justify-content: space-between;
                padding: 11px 11px 17px !important;
                width: 100%;

                svg {
                    fill: #ccc;
                }

                &-predictIcon {
                    color: #ff4500;
                    cursor: pointer;
                    height: 0.8em !important;
                    margin: 0.1em;
                    width: 0.8em !important;

                    &-activityList {
                        border-bottom: 1px solid #ddd;
                        height: 3.5em;
                        padding: 0.2em 0 !important;

                        &-matched {
                            background-color: #edf8fb;
                            height: 3.5em;

                            svg {
                                margin: 0 0.5rem 0 0.1rem;
                            }

                            &-activityCircle {
                                background: #fff;
                                border: 1px solid #d3d3d3;
                                border-radius: 50%;
                                height: 20px;
                                margin: 0 150%;
                                width: 20px;
                                z-index: 100;
                            }

                            &.MuiListItem-gutters {
                                padding-left: 0;
                            }
                        }
                        svg {
                            margin: 0 0.5rem 0 0.1rem;
                        }

                        &-activityCircle {
                            background: #fff;
                            border: 1px solid #d3d3d3;
                            border-radius: 50%;
                            height: 12px;
                            width: 12px;
                            z-index: 100;

                            &.NotAssigned {
                                border: 1px solid rgba(0, 0, 0, 0.2);
                                border-radius: 50%;

                                /* stylelint-disable-next-line */
                                &.Matched {
                                    border: 2px solid #808080;
                                }
                            }
                            &.Assigned {
                                background-color: #000;
                                border: 1px solid transparent;
                                border-radius: 50%;

                                /* stylelint-disable-next-line */
                                &.Matched {
                                    background-color: #f5f5f5;
                                    border: 3px solid #000;
                                }

                                /* stylelint-disable-next-line */
                                &.PredictedTime {
                                    background-color: #008000;
                                }
                            }
                        }
                        &-smallCircle {
                            background-color: none;
                            border: 2px solid rgba(0, 0, 0, 0.2);
                            border-radius: 50%;
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }

            &-none {
                display: none;
            }
        }
    }

    .ModelsExpanded__totalTime {
        // border-left: 1px solid #ddd;
        padding: 0.5em 0 0;
        text-align: center;
        width: 100%;

        .MuiTypography-body1 {
            font-size: 0.9rem;
            font-weight: bold;
        }
    }

    .Chart {
        border-left: 1px solid #ddd;
        height: 50px;
        left: 0;
        min-width: 135px;
        position: absolute;
        top: 0;
        width: 100%;

        .VarianceBox {
            border-color: #ddd;
            border-left-style: solid;
            border-right-style: hidden;
            border-width: 1px;
            bottom: 0;
            height: 140%;
            position: absolute;
            width: 100%;
        }

        .CenterBar {
            background-color: #d3d3d3;
            bottom: 10px;
            height: 60px;
            margin-right: -0.5px;
            position: absolute;
            right: 50%;
            width: 1px;
        }

        .Bar {
            animation-duration: 2s;
            animation-name: fromcenter;
            background-color: transparent;
            border-style: none;
            border-left-style: solid;
            border-width: 3px;
            position: absolute;
            right: 0;
            transition: width 2s;
            width: 1px;
            z-index: 1;
        }

        .Selected {
            border-color: #808080;
            bottom: 5px;
            height: 43px;
            top: -22px;

            &-leftPercent {
                width: 100%;
            }

            &-rightPercent {
                width: 100%;
            }
        }

        .Selected.Filler {
            height: 61px;
            top: 0;
        }

        .Applied {
            border-color: #000;
            height: 43px;
            top: -22px;
        }

        .Filler {
            height: 49px;
        }

        @keyframes fromcenter {
            0% {
                opacity: 0.5;
                width: 50%;
            }
            50% {
                opacity: 0.8;
            }
            100% {
                opacity: 1;
            }
        }

        .BarConnector {
            background-color: #d3d3d3;
            bottom: 17px;
            height: 1px;
            position: absolute;
            right: 40px;
            transition: width 2s;
            width: 1px;
        }

        .PlaceholderCircle {
            background-color: transparent;
            border-color: #d3d3d3;
            border-radius: 50%;
            border-style: solid;
            border-width: 2px;
            bottom: -18px;
            height: 7px;
            left: 32px;
            position: absolute;
            width: 7px;
        }
    }
}
