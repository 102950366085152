.ModelsList {
    padding: 1em;

    .MuiTable-root {
        margin-top: 1em;
    }

    .MuiListItem-gutters {
        padding-left: 8px;
    }

    .MuiTableCell-root {
        border: 0;
        padding: 0;
    }

    .MuiTableCell-head {
        font-weight: bold;
    }

    &__title {
        color: #016848;
        font-weight: bold !important;
        text-align: left;
    }

    &__model {
        align-items: center;
        display: flex;
        margin-left: -0.6em;

        &-badge {
            margin-left: 1em;
        }
    }

    &__instances {
        text-align: center;
    }
}
