.FilterDropDown {
    background-color: #fafafa;
    max-width: 300px;
    min-width: 200px;
    width: 100%;

    &__nav {
        display: grid;
        grid-template-areas: 'label icon';
        grid-template-columns: 80% 20%;

        &-label {
            grid-area: label;
            justify-self: flex-start;
        }
        &-icon {
            grid-area: icon;
        }
    }
}
